import React from "react"
import Helmet from "react-helmet"
import SEO from "../components/seo"
import Layout from "../components/layout"


export default function Contact() {
  return (
    <Layout>
      <SEO 
        title="Contact Us - Compute Software" 
        description="Contact sales or support. We're here to help."
      />
      <Helmet></Helmet>

      <section className="contact-us-form sub-general-content">
        <div className="container">
          <div className="demo-text col-xs-12 col-sm-12 col-md-6 col-lg-6">
            <h1>Contact Us</h1>
            <p>We’re here to help.</p>
            <br/>
            <h3>Contact Sales</h3>
            <p><a href="mailto:sales@computesoftware.com">sales@computesoftware.com</a></p>
            <br/>
            <h3>Contact Support</h3>
            <p><a href="mailto:support@computesoftware.com">support@computesoftware.com</a></p> 
          </div>
          <div className="demo-form col-xs-12 col-sm-12 col-md-6 col-lg-6">
            <form name="contact" className="was-validated" method="POST" action="/success" data-netlify="true" data-netlify-honeypot="bot-field">
              <div className="form-row row">
                <div className="form-group col-md-12">
                  <label htmlFor="reasonForContact" className="form-label">Who do you want to contact?</label>
                  <select id="reasonForContact" name="reasonForContact" className="form-control">
                    <option value="sales">Contact Sales</option>
                    <option value="support">Contact Support</option>
                  </select>
                </div>
              </div>
              <div className="form-row row first-name">
                <div className="form-group required col-md-12">
                  <label htmlFor="firstName" className="form-label">First name</label>
                  <input type="text" className="form-control" id="firstName" name="firstName" required />
                </div>
              </div>
              <div className="form-row row last-name">
                <div className="form-group required col-md-12">
                  <label htmlFor="lastName" className="form-label">Last name</label>
                  <input type="text" className="form-control" id="lastName" name="lastName" required />
                </div>
              </div>
              <div className="form-row row work-email">
                <div className="form-group required col-md-12">
                  <label htmlFor="workEmail" className="form-label">Work email</label>
                  <input type="email" className="form-control" id="workEmail" name="workEmail" required />
                </div>
              </div>
              <div className="form-row row phone-number">
                <div className="form-group col-md-12">
                  <label htmlFor="phoneNumber" className="form-label">Phone number</label>
                  <input type="text" className="form-control" id="phoneNumber" name="phoneNumber" />
                </div>
              </div>
              <div className="form-row row company">
                <div className="form-group col-md-12">
                  <label htmlFor="company" className="form-label">Company</label>
                  <input type="text" className="form-control" id="company" name="company" />
                </div>
              </div>
              <div className="form-row row job-title">
                <div className="form-group col-md-12">
                  <label htmlFor="jobTitle" className="form-label">Job title</label>
                  <input type="text" className="form-control" id="jobTitle" name="jobTitle" />
                </div>
              </div>
              <div className="form-row row cloud-spend">
                <div className="form-group col-md-12">
                  <label htmlFor="averageMonthlyCloudSpend" className="form-label">Average Monthly Cloud Spend</label>
                  <select id="averageMonthlyCloudSpend" name="averageMonthlyCloudSpend" className="form-control">
                    <option aria-label="Placeholder" value="" disabled selected></option>
                    <option>Less than $100k</option>
                    <option>$100k - $250k</option>
                    <option>$250k - $1M</option>
                    <option>More than $1M</option>
                  </select>
                </div>
              </div>
              <div className="form-row row email-subject">
                <div className="form-group required col-md-12">
                  <label htmlFor="emailSubject" className="form-label">Subject</label>
                  <input type="text" className="form-control" id="emailSubject" name="emailSubject" />
                </div>
              </div>
              <div className="form-row row email-message">
                <div className="form-group required col-md-12">
                  <label htmlFor="emailMessage" className="form-label">Message</label>
                  <textarea type="text" className="form-control" id="emailMessage" name="emailMessage" rows="6"/>
                </div>
              </div>
              <input type="hidden" name="bot-field" />
              <input type="hidden" name="form-name" value="contact"  />
              <button type="submit" className="form-submit btn">Submit</button>
              <span className="required-field-indicator">* Required fields.</span>
            </form>
          </div>
        </div>
      </section>
    </Layout>
  )
}